import React from "react";
import { IbPartnershipQuery } from "../../prismic/staticQueries/markets/index.js";
import Layout from "../../layout";
import { Subscription, BreadCrumbBanner } from "../../components/common";
import "react-multi-carousel/lib/styles.css";
import { connect, useSelector } from "react-redux";
import { Container, Row, Col } from "react-bootstrap";
import { PrismicRichText } from "@prismicio/react";
import Carousel from "react-multi-carousel";
import CountUp from "react-countup";
import banner_image from "../../images/siteImages/home/become-a-partner.png";
import markets_side_image from "../../images/siteImages/home/become-partner.jpg";
//import markets_side_image from "../../images/siteImages/StocksIcons/amazon_icon.png";
import register from "../../images/siteImages/home/register.svg";
import introduce from "../../images/siteImages/home/join-chain.svg";
import earn from "../../images/siteImages/home/earn-money.svg";
import lot from "../../images/siteImages/home/lot.svg";
import payout from "../../images/siteImages/home/payout.svg";
import support from "../../images/siteImages/home/support.svg";
import launch from "../../images/siteImages/home/launch.svg";
import PaymentSlider from "./PaymentSlider.js";
import BarAnimation from "./AreaGraphWithSlider.js";

const IbPartnership = () => {
  const language = useSelector((state) => state.language);
  const IbPartnershipData = IbPartnershipQuery(language);
  console.log("language", IbPartnershipData);
  const {
    markets_heading,
    all_partner_box,
    // markets_side_image,
    all_markets_box,
    analytic_head,
    analytic_phara,
    three_box,
  } = IbPartnershipData;

  const all_markets_icon = [
    register,
    introduce,
    earn
  ];
  const three_icon = [
    lot,
    payout,
    support,
    introduce,
    launch
  ];
  const responsive = {
    superLargeDesktop: {
      // the naming can be any, depends on you.
      breakpoint: { max: 4000, min: 3000 },
      items: 2,
    },
    desktop: {
      breakpoint: { max: 3000, min: 1024 },
      items: 2,
    },
    tablet: {
      breakpoint: { max: 1024, min: 464 },
      items: 2,
    },
    mobile: {
      breakpoint: { max: 464, min: 0 },
      items: 1,
    },
  };

  return (

    <Layout >
      <BreadCrumbBanner banner_image={banner_image} />
      <section className="sectionbg pb-5">
        <Container className="">
          <Row className="py-5">
          {/* <Col lg={12}>
            <BarAnimation/>
          </Col> */}
            <Col lg={6}>
              <h2 className="pt-5 my-3 text-bold-md text-black">{markets_heading.text}</h2>
              {all_partner_box.length > 0 && all_partner_box.map((partnermap, partnermapInd) => (
                <p className="text-gray my-3" key={"partnermapInd" + partnermapInd}>
                  {partnermap.markets_content.text}</p>
              ))}
            </Col>
            <Col lg={1}></Col>
            <Col lg={5} className="py-5">
              <img alt="ddd" src={markets_side_image} className="dubai-img" />
            </Col>
          </Row>
          <Row className="mt-5">
            {all_markets_box.length > 0 && all_markets_box.map((marketmap, marketmapInd) => (
              <Col className="mb-5"
                lg={4}
                md={6} key={"marketmapInd" + marketmapInd}>
                <div className="partner-card mb-4 h-100">
                  <div className="d-flex flex-column mb-0 align-items-center">
                    <div className="box-icon mb-4">
                      <img alt="ddd" src={all_markets_icon[marketmapInd]} className="icon-width" />
                    </div>
                    <h4 className="text-center text-bold-md mb-0 mx-3">
                      {marketmap.all_market_heading.text}
                    </h4>
                    <p> {marketmap.all_market_description.text}</p>
                  </div>
                </div>
              </Col>
            ))}
          </Row>
        </Container>
      </section>
      <section className="gradient-bg">
        <Container className="py-5">
          <Row>
            <Col md={12} lg={12}>
              <h3 className="text-white text-center">{analytic_head.text}</h3>
              <p className="text-white text-center">{analytic_phara.text}</p>
            </Col>
          </Row>
          <div className="d-flex py-3 justify-content-around">
            {three_box.length > 0 && three_box.map((threemap, threemapInd) => (
              <div className="three-card px-5" key={"threemapInd" + threemapInd}>
                <div className="h-100">
                  <div className="d-flex flex-column align-items-center">
                    <img alt="ddd" src={three_icon[threemapInd]} className="img-size" />
                    <h5 className="mb-0"> {threemap.three_heading.text}</h5>
                  </div>
                </div>
              </div>
            ))}
          </div>
        </Container>
      </section>

      <PaymentSlider/>
    </Layout>
  );
};
const mapStateToProps = (state) => {
  return {
    language: state.language,
  };
};

export default connect(mapStateToProps)(IbPartnership);